.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  /* justify-content: center; */
  font-size: 20px;
  gap: 20px;
  height: 100vh;
  font-family: sans-serif;
  background-color: #f8f9fd;
}

input[type="email"],
input[type="password"] {
  height: 40px;
  font-size: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  width: 96%;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

input[type="password"] {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  /* position: absolute; */
  width: 400px;
  height: 420px;

  border-radius: 10px;
  background-color: white;
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* top: 4rem;
  right: 20%; */
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}

.forgotPassword > a {
  font-size: 17px;
  color: #0d6efd;
  position: relative;
  left: 33%;
  top: 10px;
  text-decoration: none;
  padding-bottom: 20px;
}

.forgotPassword > a:hover {
  text-decoration: underline;
}

.slogan {
}

.sloganTitle {
  color: #0d6efd;
  font-size: 50px;
  font-weight: bold;
  padding: 0;
}

.sloganStatement {
  /* position: absolute;
  bottom: -40px;
  font-size: 25px; */
}

input[type="text"] > .mb-3 {
  width: 10px;
}

.signUp:hover {
  background-color: #246d15;
  border-color: #246d15;
}

.flex-item {
}
